.cursor {
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  z-index: 100000;
}
.cursor--large {
  --size: 40px;
  border: 1px solid #ff3c3c;
}
.cursor--small {
  --size: 10px;
  background: #ff3c3c;
  transform: translate(-50%, -50%);
}

.cursor {
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  background: white;
  position: fixed;
  z-index: 999;
  pointer-events: none;
  z-index: 100000;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.4);
}
.cursor-f {
  width: var(--size);
  z-index: 100000;
  height: var(--size);
  position: fixed;
  top: 0;
  left: 0;
  background-size: cover;
  pointer-events: none;
  transition: all 0.1s;
  opacity: 1;
}
.cursor-f svg,
.cursor-f path {
  width: 100%;
  transition: all 0.3s;
  height: 100%;
  fill: rgba(0, 0, 0, 0.2);
}

.hovering svg {
  opacity: 0 !important;
}

.hovering {
  border: 1px solid #fff;
  background-color: rgba(0, 0, 0, 0.2) !important;
}
