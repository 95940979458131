.swiper_slider .swiper-slide{
  background-size: cover;
  color: #999;
  background-position: center;
  position: relative;
}

.swiper_slider{
  margin-bottom: 1rem;
}

.swiper_thumbs .swiper-slide-thumb-active::before{
  opacity: 0;
}
.swiper_thumbs .swiper-slide::before{
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  transition: all 0.5s;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.swiper_slider .swiper-slide{
  height: 40vw !important;
}

.sliders{
  display: flex;
  flex-direction: column;
  justify-content: center;
}