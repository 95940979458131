.sidebar {
  /* position: -webkit-sticky;
  position: sticky; */
  /* top: 0;
  left: 0; */
  width: 7rem;
  height: 100vh;
  opacity: 0.958;
  z-index: 10000;
}
:root {
  --px: 0;
  --py: 0;
}

.fancy-hover {
  background: #242424;
  background: rgba(36, 36, 36, 0.9);
  color: #fff;
  display: flex;
  align-items: center;
  width: 7rem;
  position: relative;
  overflow: hidden;
  border-bottom: solid 0.1rem #333;
  z-index: 2;
  transition: all 0.4s, background-color 0.2s linear;
}

.fancy-hover:before,
.fancy-hover:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: opacity 0.1s linear;
}

.fancy-hover:before {
  width: 12rem;
  height: 12rem;
  background: radial-gradient(circle at center, #fff 0%, transparent 60%);
  opacity: 0;
  mix-blend-mode: screen;
  transform: translate(calc(var(--px) * 0.1rem), calc(var(--py) * 0.1rem))
    translate(-50%, -50%);
}

.fancy-hover:after {
  background: inherit;
  margin: 0.2rem;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  transition-delay: 0.05s;
}

.fancy-hover:hover,
.active .fancy-hover {
  background-color: rgba(56, 56, 56, 0.9);
}
.fancy-hover:hover:before,
.active .fancy-hover::before {
  opacity: 0.6;
}
.fancy-hover:hover:after,
.active .fancy-hover::after {
  opacity: 0.85;
  transition-delay: 0s;
}

/* ////////////////////////////////////////////////////////////////////////// */

.menu {
  border: solid 0.1rem #444444;
  border-radius: 0.3rem;
  opacity: 0.958;
  margin-top: 10rem;
  height: calc(100% - 11rem);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.menu :last-child {
  margin-bottom: 0;
}
.menu .fancy-hover:last-child {
  border-top: solid 0.1rem #333;
}
.menu_icon {
  margin: 0 auto;
  height: 100%;
  display: inline-flex;
  font-size: 1.7rem;
  text-align: center;
  padding: 1.5rem 0;
  justify-content: center;
  align-items: center;
  transition: all 0.4s;
}

.menu_icon svg {
  width: 2.5rem;
  fill: #fff;
}

.fancy_outer {
  position: relative;
}

.icon_name {
  position: absolute;
  font-size: 1.7rem;
  left: calc(100% + 2rem);
  top: 50%;
  border-radius: 0.5rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  transform: translateY(-50%) scaleX(0);
  background-color: #111;
  color: #fff;
  padding: 0.7rem 1.4rem;
  transition: all 0.5s;
  transform: translate(-50px, -50%);
  opacity: 0;
  visibility: hidden;
  transform-origin: left;
  white-space: nowrap;
}
.icon_name::before {
  content: "";
  width: 1rem;
  height: 1rem;
  -webkit-clip-path: polygon(100% 0, 0 50%, 100% 100%);
  clip-path: polygon(100% 0, 0 50%, 100% 100%);
  background-color: #111;
  position: absolute;
  top: 50%;
  right: 100%;
  transform: translate(0, -50%);
}

.fancy_outer:hover .icon_name,
.active .fancy_outer .icon_name {
  transform: translate(0, -50%);
  opacity: 1;
  visibility: visible;
}

.blast:hover g {
  fill: #08fdd8;
  transition: all 0.4s;
}

.sidebar_expand .fancy-hover {
  width: 20rem;
}

.sidebar_expand .fancy-hover .menu_icon {
  margin: 0 1rem 0 2rem;
}
.sidebar .fancy-hover .icon_name_inner {
  display: none;
}
.sidebar_expand .fancy-hover .icon_name_inner {
  -webkit-animation: fadeText 0.4s;
  animation: fadeText 0.4s;
  font-size: 1.6rem;
  letter-spacing: 0.5px;
  display: block;
}
@-webkit-keyframes fadeText {
  0% {
    opacity: 0;
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeText {
  0% {
    opacity: 0;
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.sidebar_expand .fancy-hover .menu_icon:last-child {
  margin-left: auto;
  margin-right: 2rem;
}
.sidebar_expand .fancy-hover .menu_icon:last-child {
  margin-left: auto;
  margin-right: 2rem;
}
.back_icon {
  display: inline-block;
  text-align: right;
  transition: all 0.4s;
  transform: rotate(0);
}

.sidebar_expand .fancy-hover .back_icon {
  transform: rotate(180deg);
}

.hide_site {
  overflow: hidden;
}

.sidebar_outer::before {
  content: "";
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #000;
  opacity: 0.8;
}

.sidebar_outer {
  position: -webkit-sticky;
  position: sticky;
  z-index: 1000;
  top: 0;
  left: 0;
}

.fancy-hover:hover svg,
.active .fancy-hover svg {
  display: inline-block;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-name: rubberBand;
  animation-name: rubberBand;
}
