/* ? Each Skill ? */
.skill {
  position: relative;
  max-width: 100%;
  width: 45rem;
  display: flex;
  padding: 2rem 0.75rem 2rem;
  background-image: linear-gradient(#616161 0, #333 10%, #222);
  margin: 1.5rem auto;
  border-radius: 1rem;
  border: 0.1rem solid #000;
  transition: all 0.5s;
  overflow: hidden;
}

.skill::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background-color: rgba(255, 255, 255, 0.15);
  z-index: 10;
}
/* ? Name & Percentage ? */
.skill .name,
.skill .value {
  position: relative;
  width: 10rem;
  text-align: right;
  color: #fff;
  text-transform: uppercase;
  font-size: 1.5rem;
}
.skill .value {
  width: 4rem;
  text-align: left;
}
/* ? Progress Bar ? */
.percentage {
  position: relative;
  width: calc(100% - 14rem);
  margin: 0 0.5rem;
  height: 2rem;
  border-radius: 10rem;
  background-color: #151515;
  box-shadow: inset 0 0 1rem #000;
  overflow: hidden;
}
/* ? Displayed Percentage ? */
.percentage::before {
  position: absolute;
  content: "";
  height: 100%;
  border-radius: 10rem;
  width: calc(var(--percentage) * 1%);
  background-color: #fff;
  background: var(--color);
  box-shadow: inset 0 0 0.2rem #000;
}
.percentage_animation::before{
  -webkit-animation: width 2s ease-in-out;
          animation: width 2s ease-in-out;
}
@-webkit-keyframes width {
  from {
    width: 0;
  }
}
@keyframes width {
  from {
    width: 0;
  }
}