footer svg {
  width: 2.6rem;
}

footer {
  background: #1f1f1f;
  font-size: 1.8rem;
}

.value_info {
  font-size: 1.3rem;
}

footer .logo {
  text-align: center;
  color: #fff;
  font-size: 4rem;
  padding: 1rem 0;
}

footer .logo * {
  font-family: Audiowide, cursive;
}

footer h2 {
  font-family: Audiowide, cursive;
  font-size: 3rem;
  word-spacing: 1rem;
}

footer .row {
  color: #ccc;
  margin-bottom: 0;
}

footer .copy {
  font-family: "Audiowide", cursive;
  display: inline-block;
}

footer .business_nfo {
  display: flex;
  flex-wrap: wrap;
}

footer .business_nfo .tag {
  flex-grow: 1;
  position: relative;
  width: 50%;
  margin-bottom: 3rem;
  display: flex;
  align-items: center;
}

footer .business_nfo .nfo {
  padding-left: 1.4rem;
  display: inline-block;
}
