.about {
  min-height: 100vh;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-size: 1.65rem;
  letter-spacing: 0.1rem;
}

.hello {
  font-size: 5rem;
  font-family: "Quicksand", sans-serif !important;
  font-weight: bold !important;
  padding: 0 !important;
  margin: 0 !important;
}

.about_me {
  font-size: 2.4rem;
  width: 100%;
  font-weight: bold;
}
.about .react-reveal {
  display: inline-block;
  color: var(--color-2);
  text-transform: uppercase;
}
.about_me .react-reveal {
  display: inline-block;
}

.icon_svg path {
  stroke-width: 1.5rem;
  stroke-linecap: round;
  stroke: transparent;
  fill: #fff;
  -webkit-animation: icon_appear_about 2s ease-in-out;
  animation: icon_appear_about 2s ease-in-out;
}
@-webkit-keyframes icon_appear_about {
  0% {
    stroke-dasharray: 46760px;
    stroke-dashoffset: 46760px;
    stroke: #fff;
    fill: transparent;
  }
  80% {
    fill: transparent;
    stroke: #fff;
  }
}
@keyframes icon_appear_about {
  0% {
    stroke-dasharray: 46760px;
    stroke-dashoffset: 46760px;
    stroke: #fff;
    fill: transparent;
  }
  80% {
    fill: transparent;
    stroke: #fff;
  }
}

.whitespace {
  white-space: pre;
}

.blast {
  transition: all 0.3s ease-out;
}

.blast:hover {
  color: var(--color-1);
}

.buttonD {
  display: inline-block;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

.rubberBand {
  -webkit-animation-name: rubberBand;
  animation-name: rubberBand;
}

.buttonD:hover {
  -webkit-animation-name: rubberBand;
  animation-name: rubberBand;
}

#svg:hover svg {
  -webkit-animation-name: rubberBand;
  animation-name: rubberBand;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

@-webkit-keyframes rubberBand {
  from {
    transform: scale3d(1, 1, 1);
  }

  30% {
    transform: scale3d(1.25, 0.75, 1);
  }

  40% {
    transform: scale3d(0.75, 1.25, 1);
  }

  50% {
    transform: scale3d(1.15, 0.85, 1);
  }

  65% {
    transform: scale3d(0.95, 1.05, 1);
  }

  75% {
    transform: scale3d(1.05, 0.95, 1);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

@keyframes rubberBand {
  from {
    transform: scale3d(1, 1, 1);
  }

  30% {
    transform: scale3d(1.25, 0.75, 1);
  }

  40% {
    transform: scale3d(0.75, 1.25, 1);
  }

  50% {
    transform: scale3d(1.15, 0.85, 1);
  }

  65% {
    transform: scale3d(0.95, 1.05, 1);
  }

  75% {
    transform: scale3d(1.05, 0.95, 1);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}
.about_icon {
  width: 30rem;
}

.about_me .react-reveal {
  white-space: nowrap;
}
