/*
 !PROJECTS
*/

/* ?CARD */

.myCard {
  position: relative;
  width: 40rem;
  max-width: 100%;
  height: 20rem;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.myCard:before,
.myCard:after {
  content: "";
  z-index: 99;
  position: absolute;
  top: -1rem;
  left: 3.2rem;
  display: block;
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 1.6rem;
  background-color: #ccc;
}
.myCard:after {
  bottom: -1rem;
  top: auto !important;
}
.myCard .dots {
  z-index: 99;
  position: absolute;
  left: 3.9rem;
  top: 0.5rem;
  list-style-type: none;
}
.myCard .dots li {
  width: 0.2rem;
  height: 0.2rem;
  border-radius: 0.2rem;
  margin: 0.6rem 0;
  background-color: #ccc;
}
.project {
  position: relative;
  margin: 1rem auto;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  max-width: 100%;
  transition: all 0.4s;
  transform: perspective(100rem);
  --length: 1rem;
}
.project h2 {
  text-transform: uppercase;
  color: #fff;
}

.myCard h2 {
  z-index: 99;
  font-size: 4rem;
  font-weight: 700;
  position: absolute;
  right: 7rem;
  bottom: 1rem;
  color: #fff;
  text-shadow: 0 0 1rem #000;
  transition: all 0.4s;
}
.project::before,
.project::after {
  content: "";
  position: absolute;
  box-shadow: 0 8px 32px 0 inset rgba(255, 255, 255, 0.1);
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(131, 131, 131, 0.1);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  --skew: 45deg;
  --translate: 100%;
}

.project::before {
  left: calc(var(--length) / 2);
  height: var(--length);
  width: var(--translate);
  bottom: 0;
  transform: translateY(var(--translate)) skewX(var(--skew));
}
.project::after {
  height: var(--translate);
  width: var(--length);
  top: calc(var(--length) / 2);
  right: 0;
  transform: translateX(var(--translate)) skewY(var(--skew));
}
.upper_heading {
  color: #444;
  text-align: center;
  margin: 0;
  padding: 1rem;
  text-shadow: none;
  font-size: 2rem;
  transition: all 0.4s;
  position: relative;
  overflow: hidden;
  font-weight: bold;
  -webkit-animation: heightChange 0.1s ease-in-out;
  animation: heightChange 0.1s ease-in-out;
  transition: all 0.1s;
}
@-webkit-keyframes heightChange {
  from {
    height: 0;
  }
}
@keyframes heightChange {
  from {
    height: 0;
  }
}
.upper_heading i {
  top: 50%;
  transform: translateY(-50%);
}
.upper_heading i,
.upper_heading i::before,
.upper_heading i::after {
  height: 1.2rem;
  width: 1.2rem;
  display: inline-block;
  background-color: #ff5f57;
  border-radius: 50%;
  position: absolute;
  left: 1rem;
}
.upper_heading i::before {
  content: " ";
  left: 1.6rem;
  background-color: #ffbd2e;
  top: 0;
}
.upper_heading i::after {
  content: " ";
  left: 3.2rem;
  background-color: #28ca41;
  top: 0;
}
.myCard .pic {
  width: 80%;
  height: 100%;
  background-size: 100% 100%;
  display: inline-flex;
  justify-content: flex-end;
  align-items: flex-start;
  transition: all 0.4s ease-in-out;
  position: relative;
}
.myCard .pic::before,
.myCard .pic::after {
  content: "";
  position: absolute;
  top: 0;
  width: 0.3rem;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  transform: scaleY(0);
  transform-origin: center;
  z-index: 0;
  transition: transform 0.4s, width 0.4s 0.4s ease-in-out;
}
.project:hover .pic::before,
.project:hover .pic::after {
  width: 50%;
  transform: scaleY(1);
}
.myCard .pic::before {
  left: 0;
}
.myCard .pic::after {
  right: 0;
  z-index: 0;
}

.myCard .view_more {
  position: absolute;
  right: 1rem;
  bottom: 1rem;
  width: 3rem;
  height: 3rem;
  background: var(--btn-color);
  border: none;
  padding: 0 1rem;
  border-radius: 3rem;
  outline: none;
  transition: all 0.3s 0s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
}
.project:hover .view_more {
  width: 100%;
  height: 5rem;
  right: 0rem;
  bottom: 0rem;
  border-radius: 0;
  transition: all 0.3s 0.4s ease-in-out;
}
.project .view_more::before {
  content: "";
  transform: scale(0);
  color: #fff;
}
.project:hover .view_more::before {
  transition: all 0.3s 0.5s ease-in-out;
  transform: scale(1);
  content: "View More";
  font-size: 1.8rem;
  letter-spacing: 0.15rem;
  font-family: "Quicksand", sans-serif;
  padding-right: 1rem;
}

.project:hover .pic {
  -webkit-filter: blur(0);
  filter: blur(0);
}
.my_list {
  height: 100%;
  margin: 0 auto;
  width: 80%;
  position: relative;
  display: flex;
  align-items: center;
  z-index: 1000;
}
.my_list ul {
  display: block;
  text-align: center;
  position: relative;
  margin: 0 auto;
  transform-style: preserve-3d;
}
.my_list ul li {
  display: inline-block;
  padding: 0 1rem;
  background-color: #313131;
  color: #fff;
  border-width: 0.1rem 0.1rem 0.2rem 0.1rem;
  border-style: solid;
  border-color: #222;
  border-radius: 0.3rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.4rem;
  letter-spacing: 0.1rem;
  box-shadow: 0.1rem 0.1rem 0 rgba(0, 0, 0, 0.25);
  margin: 0.2rem;
  width: auto;
  height: auto;
  position: relative;
  text-shadow: -0.1rem -0.1rem 0 #3f3f3f, -0.1rem 0 0 #3f3f3f,
    -0.1rem 0.1rem 0 #3f3f3f, 0 -0.1rem 0 #3f3f3f, 0 0 0 #3f3f3f,
    0 0.1rem 0 #3f3f3f, 0.1rem -0.1rem 0 #3f3f3f, 0.1rem 0 0 #3f3f3f,
    0.1rem 0.1rem 0 #3f3f3f;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform-origin: 50% 50% -1rem;
  transform-style: flat;
  transform: rotateX(92deg);
  transition: transform 0.3s 0 cubic-bezier(0.5, 1.5, 0.5, 1.5);
}
.project:hover ul li {
  transform: rotateX(0);
  transition: transform 0.3s calc(0.9s + var(--i) * 0.33s)
    cubic-bezier(0.5, 1.5, 0.5, 1.5);
}

.ribbon:before,
.ribbon:after {
  content: "";
  position: absolute;
}

.ribbon {
  --width: calc(40rem / 8);
  width: var(--width);
  height: 60%;
  background: var(--btn-color);
  opacity: 0.9;
  top: -0.6rem;
  right: 2.5rem;
  position: absolute;
  clip-path: polygon(150% 0, 100% 100%, 50% 75%, 0 100%, 0 0);
  -webkit-clip-path: polygon(150% 0, 100% 100%, 50% 75%, 0 100%, 0 0);
  -ms-clip-path: polygon(150% 0, 100% 100%, 50% 75%, 0 100%, 0 0);
  -moz-clip-path: polygon(150% 0, 100% 100%, 50% 75%, 0 100%, 0 0);
  -o-clip-path: polygon(150% 0, 100% 100%, 50% 75%, 0 100%, 0 0);
}
.ribbon::before {
  height: 0.6rem;
  width: 100%;
  right: -0.3rem;
  background: var(--btn-color);
  transform: skewX(45deg);
  z-index: -1;
}
