.section-title {
  margin: 2rem 0;
  padding-bottom: 2rem;
  font-weight: 400;
  display: inline-block;
  text-align: center;
  position: relative;
  color: #fff;
  font-family: "Audiowide", cursive;
}

.testimonial_heading {
  font-size: 4rem;
}

.section-title:after,
.section-title:before {
  content: "";
  position: absolute;
  bottom: 0;
}
.section-title:after {
  height: 0.2rem;
  background-color: var(--color-1);
  left: 10%;
  opacity: 0.6;
  right: 10%;
}

.section-title:before {
  width: 1.5rem;
  height: 1.5rem;
  border: 0.3rem solid #fff;
  background-color: var(--color-1);
  left: 50%;
  transform: translatex(-50%);
  bottom: -0.6rem;
  z-index: 9;
  border-radius: 50%;
}

/* CAROUSEL STARTS */
.testimonials img {
  width: 8.5rem;
  height: 8.5rem;
  border-radius: 50%;
  border: 0.3rem solid #2e2e2e;
}

.feedback-slider-item {
  position: relative;
  padding: 4rem 4rem 6rem;
}

.customer-name {
  margin-top: 1.5rem;
  margin-bottom: 2.5rem;
  font-size: 2rem;
  font-family: "Audiowide";
}

.feedback-slider-item p {
  line-height: 2;
  font-size: 1.6rem;
  color: #fff;
  text-align: justify;
}

.feedback-slider-item h3 {
  color: #fff;
}

.customer-rating {
  background-color: #1f1f1f;
  border: 0.3rem solid #2e2e2e;
  color: rgba(253, 253, 253, 0.702);
  font-weight: 700;
  border-radius: 50%;
  position: absolute;
  width: 4.7rem;
  height: 4.7rem;
  line-height: 4.4rem;
  font-size: 1.6rem;
  right: 0rem;
  top: 10rem;
  text-indent: -0.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.thumb-prev .customer-rating {
  top: -2rem;
  left: 0;
  right: auto;
}

.thumb-next .customer-rating {
  top: -2rem;
  right: 0;
}

.customer-rating svg {
  position: absolute;
  top: 0.8rem;
  right: 0.8rem;
  font-weight: 600;
  width: 1rem;
}

/* GREY BACKGROUND COLOR OF THE ACTIVE SLIDER */
.feedback-slider-item:after {
  content: "";
  position: absolute;
  left: 0rem;
  right: 0rem;
  bottom: 0;
  top: 11rem;
  background-color: #1e1e1e;
  border: 0.1rem solid rgba(251, 90, 13, 0.1);
  border-radius: 1rem;
  z-index: -1;
}

.thumb-prev,
.thumb-next {
  position: absolute;
  z-index: 99;
  top: 45%;
  width: 10rem;
  height: 10rem;
  left: -12rem;
  transition: all 0.3s;
}

.thumb-next {
  left: auto;
  right: -12rem;
}

.feedback-slider-thumb img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
}

.thumb-prev:hover,
.thumb-next:hover {
  opacity: 0.8;
}

.testimonials .swiper-button-prev,
.testimonials .swiper-button-next {
  background-color: transparent;
  color: var(--color-1);
  font-size: 2.5rem;
  position: absolute;
  display: inline-block;
  top: auto;
  bottom: 1rem !important;
  transition: all 0.2s ease-in;
}

.testimonials .swiper-button-prev {
  left: auto;
  right: 55%;
}

.testimonials .swiper-button-prev:hover {
  left: auto;
  right: 56%;
}

.testimonials .swiper-button-next {
  right: auto;
  left: 55%;
}

.testimonials .swiper-button-next::after {
  content: "\2192";
  float: right;
}
.testimonials .swiper-button-prev::after {
  content: "\2190";
  float: left;
}

.testimonials .swiper-button-next:hover {
  right: auto;
  left: 56%;
}

/* RESPONSIVE */
@media screen and (max-width: 767px) {
  .feedback-slider-item:after {
    left: 0rem;
    right: 0rem;
  }
  .testimonials .swiper-button-prev,
  .testimonials .swiper-button-next {
    position: absolute;
    bottom: 0;
  }
  .testimonials .swiper-button-prev {
    left: 1rem;
    right: auto !important;
  }
  .testimonials .swiper-button-prev:hover {
    left: 1.5rem;
  }
  .testimonials .swiper-button-next {
    left: auto !important;
    right: 1rem !important;
  }
  .testimonials .swiper-button-next:hover {
    right: 1.5rem !important;
  }
}
