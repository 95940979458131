.my_header {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 1rem 8rem;
  transition: all 0.5s;
  top: auto;
  display: flex;
  align-items: center;
}

.scroll_header {
  background-color: #1f1f1f;
  position: fixed;
  top: 0;
  bottom: auto;
  z-index: 100000;
  padding: 1rem;
}
.header_icon svg {
  width: 5rem;
}
.header_content {
  display: flex;
  transition: all 0.5s;
}

.scroll_header .header_name {
  margin-left: 3rem;
}
.header_icon {
  transition: all 0.5s;
}

.header_name .heading,
.header_name .heading span {
  color: #fff;
  font-size: 2.5rem;
}
.header_name .heading * {
  font-family: "Audiowide", cursive;
  white-space: pre;
  font-style: normal;
}
.sub_heading {
  font-size: 2rem;
  letter-spacing: 0.4rem;
  color: #fff;
  font-weight: bold;
  white-space: pre;
}
.sub_heading i {
  font-style: normal;
}

.header_name span {
  opacity: 0;
  display: none;
}

.scroll_header .header_name span {
  display: inline-block;
  transition: all 0.5s;
  opacity: 1;
}
