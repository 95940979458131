/* ? Wrapper ? */
.skills,
.contact {
  display: flex;
  flex-wrap: wrap;
}
/* ? Heading ? */
.audiowide,
.audiowide * {
  font-family: "Audiowide", cursive;
}

.outer_container {
  width: calc(100% - 7rem);
  margin-left: auto;
}
