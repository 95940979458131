/*
 !CONTACT ME SECTION
*/
.contact {
  height: 60vh;
  overflow: hidden;
  justify-content: space-evenly;
  align-items: center;
}
.contact h1 {
  font-size: 5rem;
  font-family: "Audiowide", cursive;
}
/* CIRCLED SOCIAL ICONS STARTS */
.social-icons {
  position: relative;
  padding: 0;
  width: 20rem;
  height: 20rem;
  border-radius: 50%;
  list-style: none;
  position: relative;
  transform: rotate(-10deg);
  display: flex;
  justify-content: center;
  align-items: center;
}

/* CIRCLES */
.social-icons li {
  width: 10rem;
  height: 10rem;
  line-height: 10rem;
  text-align: center;
  border-radius: 50%;
  background-color: rgba(98, 179, 26, 0.78);
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
  transform-origin: left bottom;
  text-transform: uppercase;
}
.rotate_icons li {
  -webkit-animation: rotatein 2s ease-in-out;
  animation: rotatein 2s ease-in-out;
}
@-webkit-keyframes rotatein {
  from {
    transform: rotate(0deg) translate(0, 0);
  }
}
@keyframes rotatein {
  from {
    transform: rotate(0deg) translate(0, 0);
  }
}
/* SINGLE CIRCLE ITEMS */
.social-icons .contact_link_0 {
  transform: rotate(0deg) translate(1.5rem, -3rem);
}

.social-icons .contact_link_2 {
  transform: rotate(60deg) translate(1.5rem, -3rem);
}

.social-icons .contact_link_3 {
  transform: rotate(120deg) translate(1.5rem, -3rem);
}

/*MIDDLE ONE*/
.social-icons .contact_link_6 {
  position: static;
  width: 16rem;
  height: 16rem;
  z-index: 1;
}

.social-icons .contact_link_4 {
  transform: rotate(180deg) translate(1.5rem, -3rem);
}

.social-icons .contact_link_5 {
  transform: rotate(240deg) translate(1.5rem, -3rem);
}

.social-icons .contact_link_1 {
  transform: rotate(300deg) translate(1.5rem, -3rem);
}

/* SOCIAL ICONS */
.social-icons li i {
  width: 5rem;
  height: 5rem;
  line-height: 5rem;
  font-size: 2.2rem;
  text-align: center;
  background-color: #fff;
  border-radius: 50%;
}

.social-icons i {
  width: 6rem;
  height: 6rem;
  line-height: 6rem;
  font-size: 2.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.social-icons svg {
  width: 2.6rem;
  height: 2.6rem;
}

.social-icons .contact_link_6 svg {
  transform: rotate(10deg);
}
.social-icons li a {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  position: relative;
  color: #333;
  border-radius: 50%;
}

/* REVERT THE ROTATION OF TEXTS & ICONS */
.social-icons .contact_link_0 a {
  transform: rotate(10deg);
}
.social-icons li a {
  background: var(--color);
  color: var(--color-1);
}
.social-icons .contact_link_2 a {
  transform: rotate(-50deg);
}

.social-icons .contact_link_3 a {
  transform: rotate(-110deg);
}
.social-icons .contact_link_4 a {
  transform: rotate(-170deg);
}

.social-icons .contact_link_5 a {
  transform: rotate(-230deg);
}

.social-icons .contact_link_1 a {
  transform: rotate(-290deg);
}

/* SOCIAL TEXTS */
.social-icons li span {
  color: #000;
  text-decoration: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  line-height: 1;
  transition: all 0.5s ease-in-out;
  opacity: 0;
  visibility: hidden;
  font-size: 1.6rem;
  color: #fff;
}
.social-icons li:hover span {
  opacity: 1;
  visibility: visible;
}

/* RIGHT 3 TEXTS */
.social-icons .contact_link_0 span {
  right: -9.2rem;
}
.social-icons .contact_link_2 span {
  right: -7.8rem;
}
.social-icons .contact_link_3 span {
  right: -10.2rem;
}

/* LEFT 3 TEXTS */
.social-icons .contact_link_4 span {
  left: -9.2rem;
}
.social-icons .contact_link_5 span {
  left: -12rem;
}
.social-icons .contact_link_1 span {
  left: -10.2rem;
}

/* SMALL ARROWS ON THE CIRCLES */
.social-icons li a:before {
  content: "";
  width: 0;
  height: 0;
  border-bottom: 0.7rem solid transparent;
  border-top: 0.7rem solid transparent;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

/* RIGHT 3 ARROWS */
.social-icons .contact_link_0 a:before,
.social-icons .contact_link_2 a:before,
.social-icons .contact_link_3 a:before {
  right: 0;
  border-right: 1.4rem solid #fff;
}
/* LEFT 3 ARROWS */
.social-icons .contact_link_4 a:before,
.social-icons .contact_link_5 a:before,
.social-icons .contact_link_1 a:before {
  left: 0;
  border-left: 1.4rem solid #fff;
}

/* DOTTED */
.social-icons li:after {
  content: "";
  transform: rotate(-49deg) translate(0, 0.7rem);
  display: block;
  background-repeat: repeat-x;
  background-size: 1rem 0.5rem;
  height: 0.5rem;
  background-image: radial-gradient(circle, #fff 0.1rem, transparent 0.15rem);
  transform-origin: left center;
  position: relative;
  z-index: -1;
  width: 6rem;
  transition: all 0.5s ease-in-out;
}

.social-icons .contact_link_2:after,
.social-icons .contact_link_3:after,
.social-icons .contact_link_4:after {
  transform: rotate(-52deg) translate(0, 0.7rem);
}

/*REMOVING THE MIDDLE ONE*/
.social-icons .contact_link_6::after {
  content: none;
}

/* HOVER */
.social-icons li a i,
.social-icons li {
  transition: transform 0.3s ease-in, background-color 0.4s ease-in-out,
    color 0.45s;
}

.social-icons li a:hover i {
  transform: rotate(360deg) scale(1.1);
  background-color: rgba(115, 178, 53, 1);
  color: #fff;
}
.contact_link_6:hover ~ li::after {
  opacity: 0;
  visibility: hidden;
}
.social-icons li a:hover {
  background: #fff;
  box-shadow: 0 0 0 0.1rem #e3e3e3;
}

.social-icons li a:hover i {
  background: var(--color);
}

.social-icons a path {
  fill: currentColor !important;
}

/* RESPONSIVE CSS */
@media screen and (max-width: 600px) {
  .social-icons li span {
    display: none;
  }
  .social-main {
    transform: scale(0.75);
  }
}

@media screen and (max-width: 360px) {
  .social-main {
    transform: scale(0.6);
  }
}
